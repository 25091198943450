import request from './server'

// 支付相关的 API
export function createOrder(data) {
  return request({
    url: '/order/create',
    method: 'post',
    data
  })
}

export function getOrderStatus(orderId) {
  return request({
    url: `/order/status/${orderId}`,
    method: 'get'
  })
}

export function cancelOrder(orderId) {
  return request({
    url: '/order/cancel',
    method: 'post',
    data: { orderId }
  })
}
export function translate(data) {
  return request({
    url: '/addons/shop/api.goods/translate',
    method: 'post',
    data
  })
}
export function add(data) {
  return request({
    url: '/addons/shop/api.cart/add',
    method: 'post',
    data
  })
}
export function goods(data) {
  return request({
    url: '/addons/shop/api.cart/index',
    method: 'post',
    data
  })
}
export function spList(data) {
  return request({
    url: '/addons/shop/api.goods/index',
    method: 'post',
    data
  })
}
export function setNums(data) {
  return request({
    url: '/addons/shop/api.cart/set_nums',
    method: 'post',
    data
  })
}
export function addOrder(data) {
  return request({
    url: '/addons/shop/api.order/add',
    method: 'post',
    data
  })
}
export function translateDetail(data) {
  return request({
    url: '/addons/shop/api.goods/translateDetail',
    method: 'post',
    data
  })
}
export function translateTwo(data) {
  return request({
    url: '/addons/shop/api.goods/translateTwo',
    method: 'post',
    data
  })
}
export function getIndex(data) {
  return request({
    url: '/addons/shop/api.category/index',
    method: 'post',
    data
  })
}
export function getDetail(data) {
  return request({
    url: '/addons/shop/api.category/detail',
    method: 'post',
    data
  })
}
export function getCategory(data) {
  return request({
    url: '/addons/shop/api.category/category',
    method: 'post',
    data
  })
}
export function comment(data) {
  return request({
    url: '/addons/shop/api.category/comment',
    method: 'post',
    data
  })
}
export function detailCategory(data) {
  return request({
    url: '/addons/shop/api.category/detailCategory',
    method: 'post',
    data
  })
}
export function cartOne(data) {
  return request({
    url: '/addons/shop/api.cart/translate',
    method: 'post',
    data
  })
}
export function helpIndex(data) {
  return request({
    url: '/addons/shop/api.cateGory/helpIndex',
    method: 'post',
    data
  })
}

export function languageList(data) {
  return request({
    url: '/addons/shop/api.cart/languageList',
    method: 'post',
    data
  })
}
export function topIndex(data) {
  return request({
    url: '/addons/shop/api.cart/topIndex',
    method: 'post',
    data
  })
}
export function languageApp(data) {
  return request({
    url: '/addons/shop/api.cart/languageApp',
    method: 'post',
    data
  })
}
export function languageAbout(data) {
  return request({
    url: '/addons/shop/api.cart/languageAbout',
    method: 'post',
    data
  })
}
export function languageQuestion(data) {
  return request({
    url: '/addons/shop/api.cart/languageQuestion',
    method: 'post',
    data
  })
}
export function languageProduct(data) {
  return request({
    url: '/addons/shop/api.cart/languageProduct',
    method: 'post',
    data
  })
}
export function languageArchive(data) {
  return request({
    url: '/addons/shop/api.cart/languageArchive',
    method: 'post',
    data
  })
}
export function languageNews(data) {
  return request({
    url: '/addons/shop/api.cart/languageNews',
    method: 'post',
    data
  })
}
export function languageGd(data) {
  return request({
    url: '/addons/shop/api.cart/languageGd',
    method: 'post',
    data
  })
}
export function upload(data) {
  return request({
    url: '/api/common/upload',
    method: 'post',
    data
  })
}
export function coin(data) {
  return request({
    url: '/addons/shop/api.cart/coin',
    method: 'post',
    data
  })
}
export function languageCoin(data) {
  return request({
    url: '/addons/shop/api.cart/languageCoin',
    method: 'post',
    data
  })
}
export function nft(data) {
  return request({
    url: '/addons/shop/api.cart/nft',
    method: 'post',
    data
  })
}
export function agreement(data) {
  return request({
    url: '/addons/shop/api.cart/agreement',
    method: 'post',
    data
  })
}
export function languageEvaluate(data) {
  return request({
    url: '/addons/shop/api.cart/languageEvaluate',
    method: 'post',
    data
  })
}
export function downloadUrl(data) {
  return request({
    url: '/addons/shop/api.cart/downloadUrl',
    method: 'post',
    data
  })
}
export function getWebsiteTitle(data) {
  return request({
    url: '/addons/shop/api.cart/company',
    method: 'post',
    data
  })
}
export function supplier(data) {
  return request({
    url: '/addons/shop/api.cart/supplier',
    method: 'post',
    data
  })
}
export function walletAddress(data) {
  return request({
    url: '/addons/shop/api.cart/walletAddress',
    method: 'post',
    data
  })
}
export function orderState(data) {
  return request({
    url: '/addons/shop/api.order/orderState',
    method: 'post',
    data
  })
}