<template>
  <el-drawer
    :visible="cartVisible"
    direction="rtl"
    size="550px"
    :show-close="true"
    :with-header="false"
    custom-class="cart-drawer"
    append-to-body
  >
    <!-- 购物车标题 -->
    <div class="cart-header">
      <div class="title-wrap">
        <span class="cart-title">购物袋</span>
        <span class="badge">{{ cartItems.length }}</span>
      </div>
      <i
        class="el-icon-close close-icon"
        size="50"
        color="#ffffff"
        @click="$emit('update:cartVisible', false)"
      ></i>
    </div>

    <!-- 购物车列表 -->
    <div class="cart-content">
      <!-- 动态渲染购物车商品 -->
      <div v-for="item in cartItems" :key="item.id">
        <!-- 普通商品项 -->
        <div class="cart-item">
          <div class="item-left">
            <div class="item-image">
              <img :src="item.goods.image" />
            </div>
            <div class="item-info">
              <div class="item-name">
                {{ item.title }}
                <span v-if="item.variant" class="item-variant">{{ item.variant }}</span>
              </div>
              <div
                style="display: flex;align-items: center;gap: 10px;justify-content: space-between;"
              >
                <div class="quantity-control">
                  <button class="ctrl-btn" @click="decreaseQuantity(item)">-</button>
                  <span class="add">{{ item.nums }}</span>
                  <button class="ctrl-btn" @click="increaseQuantity(item)">+</button>
                </div>
                <div class="item-price">${{ item.subtotal }}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- Sifu教程提示 -->
        <!-- <div class="sifu-promo" v-if="!isSifu">
          <div class="sifu-icon">
            <img
              src="https://asset.onekey-asset.com/portal/2d8781f921cd68545df144edaef41dee22332d76/static/sifu-4fe233bcb6bbdded36ed6203efc4011c.png"
              alt="Sifu"
            />
          </div>
          <div class="sifu-info">
            <div class="sifu-title">OneKey Sifu 上手教程</div>
            <div class="sifu-desc">通过一对一的在线会话，获得有关设置您的 OneKey 硬件钱包的帮助。</div>
          </div>
          <button class="add-sifu-btn" @click="addSifu">添加 $79</button>
        </div> -->
      </div>

      <!-- 推荐商品标题 -->
      <!-- <div class="recommend-title">顺带把它们也加入到您的购物袋</div> -->

      <!-- 推荐商品网格 -->
      <!-- <div class="recommend-grid">
        <div v-for="item in recommendedItems" :key="item.id" class="recommend-item">
          <img :src="item.image" :alt="item.name" />
          <div class="recommend-name">{{ item.title }}</div>
          <div class="recommend-price">${{ item.price }}</div>
          <button class="add-to-cart" @click="addToCart(item)">
            添加至购物袋
            <i class="el-icon-shopping-cart-2"></i>
          </button>
        </div>
      </div> -->
    </div>

    <!-- 底部结算区 -->
    <div class="cart-footer">
      <div class="shipping">
        <span class="yun">运输费用</span>
        <span class="free">免费</span>
      </div>
      <div class="total">
        <span class="text">总计费用</span>
        <span class="total-amount">${{ totalPrice }}</span>
      </div>
      <button class="checkout-btn" @click="handleCheckout">结账</button>
      <div
        class="payment-help"
        style="display: flex; align-items: center;padding: 10px 0;justify-content: center;"
      >
        <i class="el-icon-question"></i>
        <span class="payment-help-text">如何使用加密货币支付?</span>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { goods, spList, setNums, add } from "@/api/payment";
export default {
  name: "OrderDrawer",
  props: {
    cartVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      code: localStorage.getItem("language") || "tw",
      isSifu: false,
      cartItems: [],
      recommendedItems: [],
      sifuTutorial: {
        id: "sifu",
        type: "sifu",
        name: "OneKey Sifu 上手教程",
        price: 79,
        quantity: 1,
        image:
          "https://asset.onekey-asset.com/portal/2d8781f921cd68545df144edaef41dee22332d76/static/sifu-4fe233bcb6bbdded36ed6203efc4011c.png"
      }
    };
  },
  mounted() {
    // if(this.cartVisible) {
    // }
  },
  computed: {
    // 计算总价
    totalPrice() {
      const itemsTotal = this.cartItems.reduce((sum, item) => {
        return sum + Number(item.subtotal) * Number(1);
      }, 0);
      return itemsTotal.toFixed(2);
    },
    // 计算商品总数
    totalItems() {
      return this.cartItems.reduce((sum, item) => sum + item.nums, 0);
    },
    // 添加新的计算属性
    hasSifu() {
      return this.cartItems.some(item => item.type === "sifu");
    }
  },
  methods: {
    spLists() {
      spList({ language: this.code }).then(res => {
        this.recommendedItems = res.data;
      });
    },
    // 获取商品
    getGoods() {
      goods({
        machine_code: this.$store.state.device.fingerprint,
        sceneval: 1,
        language: this.code
      }).then(res => {
        this.cartItems = res;
      });
    },
    // 增加商品数量
    increaseQuantity(item) {
      item.nums++;
      setNums({
        machine_code: this.$store.state.device.fingerprint,
        id: item.id,
        nums: item.nums
      }).then(() => {
        this.getGoods();
      });
      this.$emit("update:cart", this.cartItems);
    },

    // 减少商品数量
    decreaseQuantity(item) {
      if (item.nums > 1) {
        item.nums--;
        setNums({
          machine_code: this.$store.state.device.fingerprint,
          id: item.id,
          nums: item.nums
        }).then(() => {
          this.getGoods();
        });
        this.$emit("update:cart", this.cartItems);
      } else {
        // 数量为1时,询问是否删除
        this.$confirm("是否要移除该商品?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            const index = this.cartItems.indexOf(item);
            this.cartItems.splice(index, 1);
            this.$emit("update:cart", this.cartItems);
          })
          .catch(() => {});
      }
    },

    // 添加Sifu教程
    addSifu() {
      this.isSifu = true;
      if (!this.hasSifu) {
        this.cartItems.push({ ...this.sifuTutorial });
        this.$message.success("已添加Sifu教程");
        this.$emit("update:cart", this.cartItems);
      } else {
        this.$message.info("已经添加过Sifu教程了");
      }
    },

    // 添加推荐商品到购物车
    addToCart(item) {
      const data = {
        goods_id: item.id,
        machine_code: this.$store.state.device.fingerprint,
        sceneval: 1,
        nums: 1
        // quantity: 1
      };
      add(data).then(() => {
        this.getGoods();
      });
    },

    // 处理结账
    handleCheckout() {
      if (this.cartItems.length === 0) {
        this.$message.warning("购物袋是空的");
        return;
      }

      // TODO: 实现结账逻辑
      // this.$message.success("正在跳转到结账页面...");
      this.$router.push("/payment");
      // 这里可以跳转到结账页面或调用结账API
    },

    // 关闭抽屉
    closeDrawer() {
      this.$emit("update:cartVisible", false);
    },

    // 显示支付帮助
    showPaymentHelp() {
      this.$alert("这里是加密货币支付的帮助说明...", "支付帮助", {
        confirmButtonText: "知道了"
      });
    }
  },

  // 监听购物车变化
  watch: {
    cartItems: {
      handler(newItems) {
        // 可以在这里保存购物车数据到本地存储
        localStorage.setItem("cart", JSON.stringify(newItems));
      },
      deep: true
    },
    cartVisible(newVal) {
      if (newVal) {
        this.getGoods();
      }
    }
  },

  // 组件创建时从本地存储加载购物车数据
  created() {
    this.getGoods();
    this.spLists();
  }
};
</script>
<style scoped lang="scss">
::v-deep .el-drawer {
  padding: 0;
  background-color: #0a0b0d;
}
::v-deep .el-icon-close:before {
  font-size: 30px;
  color: #ffffff;
}

.cart-title {
  font-family: MiSans TC, MiSans TC;
  font-weight: 500;
  font-size: 22px;
  color: #ffffff;
  line-height: 26px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.cart-drawer {
  padding: 0;
}

.cart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 16px;
  // border-bottom: 1px solid #eee;
}

.title-wrap {
  display: flex;
  align-items: center;
  gap: 8px;
}

.badge {
  background: #f56c6c;
  color: white;
  padding: 2px 8px;
  border-radius: 20px;
  font-size: 18px;
}

.cart-content {
  height: calc(100vh - 339px);
  overflow-y: auto;
  padding: 0 16px;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px 0;
  // border-bottom: 1px solid #f5f5f5;
}

.item-left {
  display: flex;
  gap: 12px;
  width: 100%;
}

.item-image {
  width: 64px;
  height: 80px;

  img {
    width: 100%;
    height: 100%;
    // object-fit: contain;
  }
}

.item-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.item-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
}

.item-name {
  font-family: MiSans TC, MiSans TC;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  line-height: 21px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.item-variant {
  color: #999;
  margin-left: 4px;
}

.quantity-control {
  // display: inline-flex;
  // align-items: center;
  // border: 1px solid #eee;
  // border-radius: 2px;
  height: 34px;
  width: 82px;
  padding: 4px;
  border-radius: 100px 100px 100px 100px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.ctrl-btn {
  width: 28px;
  height: 22px;
  border: none;
  background: transparent;
  cursor: pointer;
  color: #666;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add {
  font-family: MiSans TC, MiSans TC;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  line-height: 19px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.quantity-control span {
  min-width: 20px;
  text-align: center;
  font-size: 13px;
}

.item-price {
  font-family: MiSans TC, MiSans TC;
  font-weight: 500;
  font-size: 20px;
  color: #cccccc;
  line-height: 23px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.sifu-promo {
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  margin: 8px 0;
}

.sifu-icon {
  width: 45px;
  height: 45px;
  margin-right: 12px;

  img {
    width: 100%;
    height: 100%;
  }
}

.sifu-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.sifu-info {
  flex: 1;
}

.sifu-title {
  font-family: MiSans TC, MiSans TC;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  line-height: 16px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.sifu-desc {
  padding-top: 5px;
  font-family: MiSans TC, MiSans TC;
  font-weight: 300;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.75);
  line-height: 14px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.add-sifu-btn {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-radius: 100px;
  transition: 0.2s ease-in-out;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  background-color: transparent;
  border-color: rgb(16, 17, 17);
  color: rgb(16, 17, 17);
  margin-left: auto;
  flex: 0 0 auto;
}

.cart-footer {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 550px;
  // background: white;
  padding: 16px;
  background: #131518;
  box-shadow: 0px -2px 0px 0px rgba(255, 255, 255, 0.15);
}

.shipping {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 8px;
  .yun {
    font-family: MiSans TC, MiSans TC;
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
    line-height: 23px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }
}

.free {
  font-family: MiSans TC, MiSans TC;
  font-weight: 500;
  font-size: 18px;
  color: #0008ff;
  line-height: 21px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  .text {
    font-family: MiSans TC, MiSans TC;
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
    line-height: 23px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }
}

.total-amount {
  font-family: MiSans TC, MiSans TC;
  font-weight: 500;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.75);
  line-height: 21px;
  text-align: right;
  font-style: normal;
  text-transform: none;
}

.checkout-btn {
  width: 100%;
  background: #0008ff;
  border: none;
  border-radius: 23px 23px 23px 23px;
  color: #101111;
  font-family: MiSans TC, MiSans TC;
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  line-height: 23px;
  font-style: normal;
  text-transform: none;
  padding: 15px 0;
  cursor: pointer;
  margin-bottom: 12px;
}

.recommend-title {
  font-family: MiSans TC, MiSans TC;
  font-weight: 500;
  font-size: 22px;
  color: #ffffff;
  line-height: 26px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.recommend-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.recommend-item {
  text-align: center;
}

.recommend-item img {
  width: 100%;
  aspect-ratio: 1;
  object-fit: contain;
  margin-bottom: 8px;
}

.recommend-name {
  font-family: MiSans TC, MiSans TC;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  line-height: 21px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}

.recommend-price {
  font-family: MiSans TC, MiSans TC;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  line-height: 19px;
  font-style: normal;
  text-transform: none;
  padding-top: 5px;
}

.add-to-cart {
  margin: auto;
  // width: 100%;
  // height: 32px;
  // border: 1px solid #ddd;
  // background: white;
  // border-radius: 16px;
  // font-size: 13px;
  cursor: pointer;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 8px 16px;
  align-items: center;
  cursor: pointer;
  border-radius: 19px 19px 19px 19px;
  border: 1px solid #0008ff;
  transition: 0.2s ease-in-out;
  background-color: #131518;
  font-family: MiSans TC, MiSans TC;
  font-weight: 500;
  font-size: 14px;
  color: #0008ff;
  line-height: 16px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-top: 5px;
}

.cart-content::-webkit-scrollbar {
  width: 6px;
}

.cart-content::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 3px;
}

.cart-item {
  transition: all 0.3s ease;
}

.cart-item-enter-active,
.cart-item-leave-active {
  transition: all 0.3s ease;
}

.cart-item-enter,
.cart-item-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.payment-help-text {
  gap: 4px;
  color: rgb(138, 145, 149);
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
}
</style>