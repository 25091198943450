export default {
  infoDatasTitle: {
    top_titele_one: 'Home',
    top_titele_two: 'Products',
    top_titele_three: 'Download',
    top_titele_four: 'Guide',
    top_titele_five: 'About Us',
    top_titele_six: 'Switch language'

  },
  infoDataFoter: {
    "content1": "Products & Services",
    "content2": "Hardware wallet",
    "content3": "App Download",
    "content4": "Instant Swap",
    "content5": "Compliance",
    "content6": "Contact Us",
    "content7": "About Us",
    "content8": "Terms of Service",
    "content9": "Corporate Profile",
    "content10": "Service Hotline",
    "content11": "Privacy Policy",
    "content12": "User Agreement",
    "content13": "Support Email",
    "content14": "Enterprise Solutions",
    "content15": "Press Center",
    "content16": "Business Partnerships",
    "content17": "Buy Crypto",
    "content18": "Sell Crypto",
    "content61": "Supported currencies",
    "content19": "Resellers",
    "content20": "Order Tracking",
  },

  infoDatas1: {
    // 首页
    "content1": "Digital Shield",
    "content2": "Your Ultimate Air-Gapped Vault",
    "content3": "for Crypto Assets",
    "content4": "Powered by world-class security engineers",
    "content5": "Enterprise-grade protection since 2018",
    "content6": "Get Your Hardware Wallet",
    "content7": "Biometric Access Control",
    "content8": "Biometric Access Control",
    "content9": "Offline Transaction Signing",
    "content10": "White-label Solutions",
    "content11": "True Ownership Guaranteed",
    "content12": "Order Now",
    "content13": "Military-Grade",
    "content14": "CC EAL6+ Certified Chip",
    "content15": "100% Open Source",
    "content16": "Public GitHub Repo",
    "content17": "Multi-layered Encryption Framework",
    "content18": "Recovery Phrase Generation",
    "content19": "Bluetooth and Encrypted QR Code",
    "content20": "Two Transaction Protocols",
    "content21": "Zero Network Exposure",
    "content22": "Core Features",
    "content23": "Support binding wallets with the same protocol, such as Little Fox, imtoken, TP, etc",
    "content24": "Supporting remote signature, it can complete pairwise QR code transfer without the need for internet connection, maximizing the security and convenience of transactions",
    "content25": "3000+ Mainstream Assets Supported",
    "content26": "BTC/ETH/SOL/DOGE...",
    "content27": "Send/Receive",
    "content28": "Simple interface design and smooth experience, easy to send and receive encrypted assets, view trading trends and asset balances.",
    "content29": "Buy/Swap",
    "content30": "You can use a mnemonic card or credit card to purchase encrypted assets, and support one click flash redemption, effectively ensuring strong market trading·",
    "content31": "Multi-Chain torage",
    "content32": "Supports multi main chain storage such as BTC, ETH, BNB, TRX, DOT, etc., making it easy to use and access on chain tokens.",
    "content33": "Open DApp Browse",
    "content34": "Support ETH, TRON and other DApps. Enter the DApp website to experience any DApp",
    "content35": "User Testimonials",
    "content36": "What is Cold Storage?",
    "content37": "It refers to offline private key management through",
    "content38": "hardware-isolated security chips.",
    "content39": "See All FAQs",
    "content40": "Start Securing Crypto Today on Digital Shield",
    "content41": "Order Now",
    "content42": "Eliminates attack surfaces",
    "content43": "while maintaining intuitive UX",
    "content44": "Private Key Never Leaves Device",
  },
  carousel: {
    items: [
      {
        title: "What is Cold Storage?",
        description: "It refers to offline private key management through",
        image: "https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/lunbo.png"
      },
      {
        title: "What is a hardware wallet?",
        description: "A hardware wallet is a cryptocurrency wallet that provides complete isolation between a private key and an easy-to-crack computer or smartphone.",
        image: "https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/lb2.png"
      },
      {
        title: "Why a hardware wallet?",
        description: "The use of hardware wallets can protect crypto assets 100% under privacy conditions, and hackers will not be able to steal or attack assets through the Internet.",
        image: "https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/lb3.png"
      }
    ]
  },
  carouselMobile: {
    items: [
      {
        title: "What is Cold Storage?",
        description: "It refers to offline private key management through",
        image: "https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/mobile/lb2.png"
      },
      {
        title: "What is a hardware wallet?",
        description: "A hardware wallet is a cryptocurrency wallet that provides complete isolation between a private key and an easy-to-crack computer or smartphone.",
        image: "https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/mobile/lb2.png"
      },
      {
        title: "Why a hardware wallet?",
        description: "The use of hardware wallets can protect crypto assets 100% under privacy conditions, and hackers will not be able to steal or attack assets through the Internet.",
        image: "https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/mobile/lb3.png"
      }
    ]
  },
  infoDataProduct: {
    // 产品中心
    "content1": "Hold Your Own Crypto",
    "content2": "Innovation driven, safety first",
    "content3": "purchase price",
    "content4": "Indestructible security",
    "content5": "100% open source",
    "content6": "Users can check the code at any time",
    "content7": "Ensure that there are no security risks such as backdoors or viruses.",
    "content8": "Built in highest level",
    "content9": "CC EAL6+",
    "content10": "security chip",
    "content11": "Multi dimensional encryption technology",
    "content12": "Create mnemonic words",
    "content13": "Ultimate protection of private key security.",
    "content14": "To safeguard encrypted assets.",
    "content15": "Innovation, Beyond Limits",
    "content16": "Supporting remote signature, completing pairwise QR code transfer without the need for internet connection maximizes the security and convenience of transactions.",
    "content17": "Hardware wallets can be bound to other wallets of the same protocol besides the Digital $hield wallet, such as Little Fox, imtoken, TP, etc Experience unparalleled",
    "content18": "Experience unparalleled",
    "content19": "Smooth and exquisite shell, clear and bright touch screen, ensuring excellent hand feel while enjoying",
    "content20": "Ultimate Visual Experience ",
    "content21": "Equipped with fingerprint unlocking function",
    "content22": "Simply tap your thumb to unlock, which is both safe and convenient",
    "content23": "Simple and intuitive",
    "content24": "The design page is clear at a glance, making it more comfortable and convenient to operate. Even beginners can easily access it",
    "content25": "Ultra long battery capacity",
    "content26": "Charging once can last for a month",
    "content27": " Support multiple chain currencies",
    "content28": "Support NFT",
    "content29": "Comparison of Digital Shield with Other Hardware Wallets",
    "content30": "brand",
    "content31": "Digital Shield",
    "content32": "Ledger",
    "content33": "Trezor",
    "content34": "KeepKey",
    "content35": "Security chip level",
    "content36": "CC EAL6+(highest level)",
    "content37": "CC EAL5+",
    "content38": "No dedicated security chip",
    "content39": "No use of secure chips",
    "content40": "Idle source degree",
    "content41": "100% source (code transparent and traceable)",
    "content42": "Partially open source",
    "content43": "Partially open source",
    "content44": "Closed source",
    "content45": "Fu's way of losing",
    "content46": "Bluetooth+encrypted QR code",
    "content47": "Bluetooth+USB",
    "content48": "USB",
    "content49": "USB",
    "content50": "Offline signature",
    "create_time": "2025-03-22 09:50:39",
    "content_51": "Support remote signature\r\n(No internet environment available)",
    "content52": "Devices need to be connected",
    "content53": "Devices need to be connected",
    "content54": "Devices need to be connected",
    "content55": "Supported currencies",
    "content56": "3000+tokens\r\nXia Gai Mainstream Chain and DApp",
    "content57": "1800+tokens",
    "content58": "1200+tokens",
    "content59": "100+tokens",
    "content60": "",
    "language_name": "英语",
    "content61": "",
    "content62": "Cold storage only",
    "content63": "Cold storage only",
    "content64": "Cold storage only",
    "content65": "Miners' yellow invasion",
    "content66": "intelligent algorithms\r\nAutomatically match the most similar rates",
    "content67": "Manual adjustment",
    "content68": "Manual adjustment",
    "content69": "Not Supported",
    "content70": "Independent use of apps without hardware",
    "content71": "support",
    "content72": "Not Supported",
    "content73": "Not Supported",
    "content74": "Not Supported",
    "content75": "Bind to other apps for use",
    "content76": "Support (can be bound to the same protocol app wallet for use)",
    "content77": "support",
    "content78": "Not Supported",
    "content79": "Not Supported",
  },
  productTable: {
    rows: [
      {
        feature: "Security chip level",
        digitalShield: "CC EAL6+(highest level)",
        ledger: "CC EAL5+",
        trezor: "No dedicated security chip",
        keepkey: "No dedicated security chip"
      },
      {
        feature: "Idle source degree",
        digitalShield: "100% source (code transparent and traceable)",
        ledger: "Partially open source",
        trezor: "Partially open source",
        keepkey: "Closed source"
      },
      {
        feature: "Fu's way of losing",
        digitalShield: "Bluetooth+encrypted QR code",
        ledger: "Bluetooth+USB",
        trezor: "USB",
        keepkey: "USB"
      },
      {
        feature: "Offline signature",
        digitalShield: "Support remote signature (No internet environment available)",
        ledger: "Devices need to be connected",
        trezor: "Devices need to be connected",
        keepkey: "Devices need to be connected"
      },
      {
        feature: "Supported currencies",
        digitalShield: "3000+tokens Xia Gai Mainstream Chain and DApp",
        ledger: "1800+tokens",
        trezor: "1200+tokens",
        keepkey: "100+tokens"
      },
      {
        feature: "Cold wallet function",
        digitalShield: "Cold Wallet function Cold and hot separation (physical isolation network)",
        ledger: "Cold storage only",
        trezor: "Cold storage only",
        keepkey: "Cold storage only"
      },
      {
        feature: "Miners' yellow invasion",
        digitalShield: "intelligent algorithms Automatically match the most similar rates",
        ledger: "Manual adjustment",
        trezor: "Manual adjustment",
        keepkey: "Manual adjustment"
      },
      {
        feature: "Independent use of apps without hardware",
        digitalShield: "support",
        ledger: "Not Supported",
        trezor: "Not Supported",
        keepkey: "Not Supported"
      },
      {
        feature: "Bind to other apps for use",
        digitalShield: "Support (can be bound to the same protocol app wallet for use)",
        ledger: "support",
        trezor: "Not Supported",
        keepkey: "Not Supported"
      }
      // ... 其他行数据
    ]
  },
  infoDatas3: {
    // app下载
    "content1": "Digital Shield",
    "content2": "Secure Assets, Worry-Free",
    "content3": "No Account Signup | Multi-Chain Support | Premium DApp Hub | Diverse Ecosystem Services",
    "content4": "Single Wallet for Cross-Chain Asset Management",
    "content5": "Digital Shield Wallet supports BTC, ETH, BNB, TRX, DOT and other major blockchain networks, providing seamless access to 3000+ on-chain tokens.",
    "content6": "Streamlined NFT Management",
    "content7": "Seamlessly store and manage all NFTs through DApp-integrated wallet infrastructure with military-grade protection.",
    "content8": "All-in-One Crypto Solution",
    "content9": "Integrated with decentralized exchange (DEX) and fiat gateway services, enabling instant token swaps and crypto purchases via debit credit cards or seed phrase recovery.",
    "content10": "Air-Gapped Account Generation",
    "content11": "Create wallet accounts in offline environments through network-isolated physical devices, eliminating attack vectors from online hackers.",
  },
  infoDataQuestion: {
    // 新手指南
    "content1": "Hello, how can we assist you today?",
    "content2": "Find Solutions Instantly",
    "content3": "Search",
    "content4": "Digital Shield ",
    "content5": "Quick Start Guide",
    "content6": "Digital Shield hot wallet, all-in-one web3 gateway",
    "content7": "FAQ",
    "content8": "FAQ",
    "content9": "Resolving 99% of user inquiries",
    "content10": "Contact support team for unresolved issues",
    "content11": "Submit Ticket",
    "content12": "Digital Shield Hardware Wallet",
    "content13": "Security & Privacy",
    "content14": "Purchases & Swaps",
    "content15": "Digital Shield Hot Wallet",
    "content16": "Transactions & Gas Fees",
    "content17": "Orders & After-Sales",
    "content18": "",
    "content19": "Can't Find What You Need?",
    "content20": "Submit Ticket",
  },
  infoDataAbout: {
    // 关于我们
    "content19": "Who We Are?",
    "content20": "Digital Shield is a crypto asset security platform established by core engineers from Feitian Technologies (a national key software enterprise), operating under Hong Kong Yuheng Network Technology Co., Ltd. Our team brings proven expertise in digital banking security, payment card solutions, mobile payment protocols, cloud authentication, identity verification, and software protection. With extensive experience in blockchain infrastructure development and crypto security architecture, we've provided technical solutions for leading hardware wallets. Our mission is to deliver cutting-edge, secure, and user-friendly digital asset storage solutions for the global crypto market.",
    "content21": "Our Expertise",
    "content22": "We combine robust engineering capabilities with innovative technologies to deliver secure yet accessible crypto management solutions. Through hardware-software integration, we enable efficient and protected digital asset stewardship.",
    "content23": "Technical Edge",
    "content24": "100% Open-Source",
    "content25": "Multi-Channel Transactions ",
    "content26": "(Encrypted QR Code, Bluetooth)",
    "content27": "Air-Gapped Signing",
    "content28": "Network Risk Elimination",
    "content29": "Cross-Protocol Wallet Binding",
    "content30": "Dynamic Fee Optimization",
    "content31": "Dynamic Fee Optimization",
    "content32": "Provide Best Gas Fee",
    "content33": "Private Key Security",
    "content34": "Multi-Layered Encryption for Seed Phrase Generation",
    "content35": "CC EAL6+ Secure Element Protection",
    "content36": "Hardware-Level Safeguarding",
    "content37": "Cold Storage Architecture",
    "content38": "True Air-Gapped Implementation",
    "content39": "User-Centric Design",
    "content40": "Simple Design",
    "content41": "Intuitive Interface",
    "content42": "Multi-Chain",
    "content43": "Multi-chain asset custody supporting native Layer 1 coins (BTC, ETH) and 3000+ compliant tokens arcoss multiple ecosystems.",
    "content44": "Dual Modes",
    "content45": "Dual transaction protocols (Secure Bluetooth Pairing +  Encrypted QR) enabling air-gapped signing with military-grade security and single-click convenience.",
    "content46": "One-Stop Service",
    "content47": "Delivering comprehensive digital asset management with integrated custody, instant swaps, DEX trading, and NFT governance through a unified interface, eliminating multi-platform switching for holistic crypto portfolio control.",
    "content48": "Where We Are?",
    "content49": "Headquartered in Hong Kong SAR, China with regional offices in:",
    "content50": "Russia",
    "create_time": "2025-03-14 17:18:10",
    "content_51": " Japan ",
    "content52": "Thailand",
    "content53": "Hong Kong",
    "content54": "Our Vision",
    "content55": "Premier Crypto Custodian",
    "content56": "We strive to become the world's most trusted digital asset management brand through continuous innovation. By building a secure, transparent, and equitable crypto ecosystem, we aim to advance the global digital economy.",
    "content57": "Strategic Partners",
    "content58": "Contact",
    "content59": "Support: www.ds.pro@gmail.com",
    "content60": "Business: DigitalShield88@gmail.com",
    "language_name": "英语",
    "content61": "Supported currencies"
  },
  infoCenter: {
    "content1": "Category",
    "content2": "Help Center",
    "content3": "return",
  },
  infoEvent: {
    "content1": "Zero Gas Fee Event, Limited Time!",
    "content2": "To celebrate the launch of Digital Shield Hot Wallet，",
    "content3": "we are excited to offer",
    "content4": " 「TRC-20 USDT transfers with 0 gas fees」",
    "content5": "a special limited-time promotion ",
    "content6": "Each user can enjoy",
    "content7": "10 completely free transactions daily! ",
    "content8": "Learn More About the Event Details",
  }
}